import './Statisics.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router';
import CopyToClipboard from 'react-copy-to-clipboard';
import { shortAddress, toLocale } from '../../config/helpers';
import AnimationComponent from '../AnimationComponent';
import StatisticsBox from './StaticsBox';
import { useAccount } from 'wagmi';
import { useEffect, useState } from 'react';
import { useSaleContractData } from '../../hooks/useContractData';
import { ExternalApi } from '../../hooks/externalApi';
import { rounds } from '../../config/rounds';
import { formatEther, formatUnits } from 'viem';
import { url } from '../../config';

const Statisics = () => {
    const { address } = useAccount()
    const [id, setid] = useState(0)
    const location = useLocation()
    const [hfgSold, setHfgSold] = useState(0);
    const [usdRaised, setUsdRaised] = useState(0);
    const [usdcRaised, setUsdcRaised] = useState(0);
    const [usdtRaised, setUsdtRaised] = useState(0);

    const salePrice = rounds.filter(data => data.address === id)[0]?.price;
    const saleSupply = rounds.filter(data => data.address === id)[0]?.ocAllotted;

    const { result: data, isLoading } = useSaleContractData(id)
    // const { result } = ExternalApi(id)


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('id');
        setid(id)
    }, [location.search])


    useEffect(() => {
        if (!isLoading) {
            // if (result === false) {
                if (data) {
                    setUsdRaised(formatUnits(data.totalRaised, 6))
                    const sale = data
                    const hfRaised = sale.hfSold
                    const totalRaised = parseFloat(formatEther(hfRaised))
                    setHfgSold(totalRaised)
                    setUsdcRaised(parseFloat(formatUnits(sale.usdcRaised, 6)))
                    setUsdtRaised(parseFloat(formatUnits(sale.usdtRaised, 6)))
                }
            // } else {
            //     setUsdRaised(result[0].usdcRaised + result[0].usdtRaised)
            //     setHfgSold(result[0].hfgSold);
            //     setUsdcRaised(result[0].usdcRaised);
            //     setUsdtRaised(result[0].usdtRaised);

            // }
           
        }
    }, [ data, setHfgSold, setUsdRaised, isLoading])

    const notify = () => toast.success('Copied Successfully!')


    return (
        <>
            <section className="statisics">
                <div className="oc_container">
                    <div className="grid grid-cols-1 lg:grid-cols-1 gap-6">
                        <div className="boxinner leftinfo">
                            <AnimationComponent y={'100'} duration={1} scale={0}>
                                <h2 className='text-4xl	md:text-5xl mb-6 text-center'>STATISTICS</h2>
                            </AnimationComponent>
                            <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 md:gap-6">
                                <div className="">
                                    <AnimationComponent y={'100'} duration={1} delay={0.2} scale={0}>
                                        <StatisticsBox
                                            tittle={"Total Supply"}
                                            amount={"9,999,999"}
                                        />
                                    </AnimationComponent>
                                </div>
                                <div className="">
                                    <AnimationComponent y={'100'} duration={1} delay={0.2} scale={0}>
                                        <StatisticsBox
                                            tittle={"Sale Supply"}
                                            amount={toLocale(saleSupply)}
                                        />
                                    </AnimationComponent>
                                </div>
                                <div className="">
                                    <AnimationComponent y={'100'} duration={1} delay={0.2} scale={0}>
                                        <StatisticsBox
                                            tittle={"Vesting Period"}
                                            amount={"You can after sale ends"}
                                        />
                                    </AnimationComponent>
                                </div>
                                <div className="">
                                    <AnimationComponent y={'100'} duration={1} delay={0.2} scale={0}>
                                        <StatisticsBox
                                            tittle={"Referral"}
                                            amount={"3% - 2% - 1% "}
                                        />
                                    </AnimationComponent>
                                </div>
                                <div className="">
                                    {address &&
                                        <AnimationComponent y={'100'} duration={1} delay={0.2} scale={0}>
                                            
                                            <StatisticsBox
                                            
                                                tittle={'Copy Referral Link'}
                                                amount={<CopyToClipboard text={`${url}?referrer=${address}`}>
                                                    <h4 onClick={notify} className='copyReferralLink text-sm -mt-[25px]' >{`${url}?referrer=${shortAddress(address)}`}</h4>
                                                </CopyToClipboard>}
                                            />
                                        </AnimationComponent>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="boxinner rightinfo">
                            <AnimationComponent y={'100'} duration={1}>
                                <h2 className='text-4xl	md:text-5xl mb-6 text-center'>SALE STATISTICS</h2>
                            </AnimationComponent>
                            <div className="grid grid-cols-1 lg:grid-cols-5 gap-4 md:gap-6">
                                <div className="">
                                    <AnimationComponent y={'100'} duration={1} delay={0.2} scale={0}>
                                        <StatisticsBox
                                            tittle={"TMT Price"}
                                            amount={salePrice}
                                        />
                                    </AnimationComponent>
                                </div>
                                <div className="">
                                    <AnimationComponent y={'100'} duration={1} delay={0.2} scale={0}>
                                        <StatisticsBox
                                            tittle={"Total Raised"}
                                            amount={usdRaised}
                                        />
                                    </AnimationComponent>
                                </div>
                                <div className="">
                                    <AnimationComponent y={'100'} duration={1} delay={0.2} scale={0}>
                                        <StatisticsBox
                                            tittle={"Total USDT"}
                                            amount={usdcRaised}
                                        />
                                    </AnimationComponent>
                                </div>
                                <div className="">
                                    <AnimationComponent y={'100'} duration={1} delay={0.2} scale={0}>
                                        <StatisticsBox
                                            tittle={"Total USDT"}
                                            amount={usdtRaised}
                                        />
                                    </AnimationComponent>
                                </div>
                                <div className="">
                                    <AnimationComponent y={'100'} duration={1} delay={0.2} scale={0}>
                                        <StatisticsBox
                                            tittle={"Total TMT Sold"}
                                            amount={hfgSold}
                                        />
                                    </AnimationComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </>
    )
}

export default Statisics