import { providers } from "ethers"
import Web3 from "web3"

export const oracleAddress = "0xa963A7D1FbE4a443927DBd51a8Cfb2C910Fb5389"

// COIN  ADDRESS
export const dwcToken = "0xf1bD5af0629467423Ccc471237742dff21ED3E7f" // DWC TOKEN 
export const usdtToken = "0xc2132d05d31c914a87c6611c10748aeb04b58e8f" // USDT TOKEN
export const usdcToken = "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174" // USDC TOKEN


export const graphQlUrl = "https://api.studio.thegraph.com/query/76981/tmt-sale-api/version/latest"

export const transactionUrl = "https://polygonscan.com/tx/"

export const chainRPC = "https://polygon-rpc.com"

export const defaultWeb3 = new Web3(chainRPC)
export const web3Provider = new providers.JsonRpcProvider(chainRPC);

export const defaultNetwork = 137

// URL used in project 
export const url = "https://sale.techmont.finance"   // referral url
export const polygonchainRPC = "https://polygon-rpc.com"

