import React from 'react'
import Sale_card_frame from './Sale_card_frame'

const SaleCard = (props) => {
    return (
        <div className="countinbox p-4 relative bg-primary border-2 border-secondary-100 rounded-2xl">
            {/* <Sale_card_frame /> */}
            <h4 className="text-center text-white text-lg md:text-2xl salestatics title-defi-font mb-6">{props.tittle}</h4>
            <div className="flex justify-between items-center px-2 sm:px-7">
                <div className="logo-glow">
                    <img src={props.img} alt={props.img_alt} width={props.img_width} height={props.img_height} className="h-12 md:h-14 w-auto" />
                </div>
                <span className="text-lg lg:text-2xl text-secondary tittle-shadow">{props.amount}</span>
            </div>
            {/* <h3>{localeFixed(486944+Number(totalocsold))}</h3> */}
        </div>
    )
}

export default SaleCard
