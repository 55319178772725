import { formatEther } from "viem";

export const localeFixed = (value, fixed = 2) => {
    return parseFloat(parseFloat(value).toFixed(fixed)).toLocaleString()
}


function dateCon(params) {
    const timestamp = Number(params) * 1000;
    const date = new Date(timestamp);
    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    return formattedDate

}


function maturityDate(params, daysToAdd) {
    const timestamp = Number(params) * 1000;
    const date = new Date(timestamp);

    // Add the specified number of days
    date.setDate(date.getDate() + daysToAdd);

    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

    return formattedDate;
}

function shortAddress(address) {
    return `${address.substr(0, 4)}...${address.substr(-4)}`;
}

function formatNumber(val, decimals = 4) {
    return parseFloat(parseFloat(formatEther(val)).toFixed(decimals)).toLocaleString()
}

function toLocale(val, decimals = 4) {
    return Number(parseFloat(val).toFixed(decimals)).toLocaleString()
}

export {
    shortAddress,
    formatNumber,
    toLocale,
    dateCon,
    maturityDate,
}



