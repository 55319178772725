import React from 'react'
import Sale_card_frame from '../Projects/Sale_card_frame'

const StatisticsBox = (props) => {
    return (
        <>
            {/* <Sale_card_frame /> */}
            <div className="bg-primary border-2 border-secondary-100 rounded-xl p-4 md:p-6 space-y-5 text-center h-full">
                <h3 className='text-xl md:text-2xl title-defi-font'>{props.tittle}</h3>
                <div className='text-base lg:text-lg text-secondary tittle-shadow'>{props.amount ? props.amount : 0}</div>
            </div>
        </>
    )
}

export default StatisticsBox
