import './Projects.css'
import { useNavigate } from "react-router-dom"
import Slale_Card_Frame from "./Slale_Card_Frame"
import AnimationComponent from "../AnimationComponent"
import { useState } from 'react';
import { useSaleContractData } from '../../hooks/useContractData';
import { ExternalApi } from '../../hooks/externalApi';
import { useEffect } from 'react';
import { formatEther, formatUnits } from 'viem';
import { toLocale } from '../../config/helpers';
import Countdown from "react-countdown";
import { useCallback } from 'react';
import TechmontCoin from "./../../Assets/images/techmont-coin.png"

const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <span>You missed it!</span>;
    } else {
        // Render a countdown
        return <span>{days}d:{hours}h:{minutes}m:{seconds}s</span>;
    }
};


function processSaleData(data, saleCap, startTime) {
    const saleStart = parseFloat(data.started);
    console.debug(saleStart)

    const hfRaised = data.hfSold;
    const endDate = ((saleStart + 30*86400) * 1000);
    const totalRaised = parseFloat(formatEther(hfRaised));
    const progress = (totalRaised / saleCap) * 100;

    return {
        status: data.state,
        hfgSold: totalRaised,
        usdcRaised: parseFloat(formatUnits(data.usdcRaised, 6)),
        usdtRaised: parseFloat(formatUnits(data.usdtRaised, 6)),
        progress,
        countdown: data.state === 1 ? endDate : startTime
    };
}


export const RoundPage = ({ address, startTime, idx, ocAllotted, price }) => {
    const saleCap = ocAllotted;

    const navigate = useNavigate()

    const [saleData, setSaleData] = useState({
        hfgSold: 0,
        usdcRaised: 0,
        usdtRaised: 0,
        progress: 0,
        countdown: 0,
        status: 0,
    });

    const { result: data } = useSaleContractData(address);
    // const externalApiResult = ExternalApi(address);

    const updateSaleData = useCallback((newData) => {
        setSaleData(prevData => ({ ...prevData, ...newData }));
    }, []);


    useEffect(() => {
            if (data) {
                updateSaleData(processSaleData(data, saleCap, startTime));
            } else {
                // updateSaleData(externalApiResult.result[0]);
            }
          
    }, [data, startTime, updateSaleData, saleCap]);

    const navigateToSaleDetails = () => {
        navigate(`/saledetails?id=${address}`);
    };

    return (
        <div id={`buySection${idx}`}>
            <AnimationComponent y={'100'} duration={1.32} delay={1}>
                <div className={`sale-card ${saleData.status === 1 ? "active_Class" : ""} ${saleData.status === 2 ? " opacity-70" : ""} flex flex-col justify-center items-center`}>
                    {/* <Slale_Card_Frame /> */}
                    <div className={`listBoxInner px-0 flex flex-col justify-center h-full active w-full bg-primary border-2 border-solid border-secondary-100 rounded-3xl`}>
                        <div className="profileInfo w-full px-5 sm:px-10 md:px-5 pt-5 sm:pt-10 md:pt-5">
                            <div className="w-full flex justify-between sm:px-0 items-center">
                                <div className="logo-brand">
                                    <img className="profilePic w-full max-w-20" src={TechmontCoin} alt="" />
                                </div>
                                <div>
                                    <h3 className="text-3xl tracking-wide">Round {idx}</h3>
                                    <ul className="mx-auto w-full flex justify-center">
                                        <li className={`upcoming ${(address) && (saleData.status === 0) && "active"}`} >Upcoming</li>
                                        <li className={`saleLive ${(address) && (saleData.status === 1) && 'active'} `}>Ongoing</li>
                                        <li className={`saleEnded ${((address) && (saleData.status === 2) && 'active')}`}>Ended</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="infoBox z-20 p-5 sm:p-10 md:p-5 overflow-hideen">
                            <p className="subtitle sm:mb-4 mb-1 text-center">Fair Launch</p>
                            <div className='flex flex-wrap items-center justify-between my-2 sm:my-4'>
                                <h3 className="text-xl">Hardcap</h3>
                                <strong className=''> {ocAllotted} TMT</strong>
                            </div>
                            <p className="progressBar">Progress <span className="text-secondary">{toLocale(saleData.progress)}%</span></p>
                            <div className="meter w-full mt-2">
                                <span style={{ width: `${toLocale(saleData.progress)}%` }}>
                                    <span className={`animateline ${saleData.status === 2 && "test"} w-full`}>
                                        <span className="absolute flex text-white top-2/4 -translate-y-2/4 right-0 z-10">
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div className="row tableBox">
                                <div className="flex justify-between mt-3">
                                    <p className="text-[#fff]">
                                        <span className="flex text-secondary mr-1">{toLocale(saleData.hfgSold)}</span>
                                        TMT
                                    </p>
                                    <p className="text-[#fff]">
                                        <span className="flex text-secondary mr-1">{saleCap.toLocaleString()}</span>
                                        TMT
                                    </p>
                                </div>
                                <p className='mt-2'>TMT Price: <span className="text-secondary"> ${price}</span></p>
                                <p className='mt-2'>TMT Sold: <span className="text-secondary">{toLocale(saleData.hfgSold)} TMT</span></p>
                            </div>
                            <div className="dividerLine my-5"></div>
                            <div className="row saleTineView mt-2 sm:mt-3 2xl:mt-4 flex">
                                {(saleData.status === 2) && <p className="timesSale text-secondary font-bold">
                                    Sale Ended
                                </p>}
                                {(saleData.status === 1) && <p className="timesSale text-secondary font-bold">
                                    Sale Ends In:
                                    <br />
                                    <Countdown date={saleData.countdown} renderer={renderer} />
                                </p>}

                                {(saleData.status === 0) && <p className="timesSale text-white font-bold">
                                    Sale Starts In:
                                    <br />
                                    <Countdown date={startTime} renderer={renderer} />
                                </p>}

                                <button className="btnView" style={{ border: 'none' }}
                                    disabled={saleData.status === 0 && true}
                                    onClick={navigateToSaleDetails}>
                                    View
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </AnimationComponent>
        </div>
    )
}