import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './BuyOc.css'
import { toast } from 'react-hot-toast';
import { useContractCall, useSaleBuy, useSaleContractData, useSaleUserData } from '../../hooks/useContractData';
import { usdcToken, usdtToken } from '../../config';
import { ethers } from 'ethers';
import { erc20ABI } from 'wagmi';
import { formatUnits } from 'viem';
import { useLocation } from 'react-router';
import { rounds } from '../../config/rounds';
import { toLocale } from '../../config/helpers';
import { addToken } from '../../hooks/addToken';

const BuyOc = () => {

    const [id, setid] = useState(0)
    const location = useLocation()

    const [usdtInput, setUsdtInput] = useState(0)
    const [usdcInput, setUsdcInput] = useState(0)
    const [hfgByUsdt, setHfgByUsdt] = useState(0)
    const [hfgByUsdc, setHfgByUsdc] = useState(0)
    const [sold, setSold] = useState(0)

    const [approvingUSDT, setApprovingUSDT] = useState(false)
    const [approvingUSDC, setApprovingUSDC] = useState(false)

    const [buyingWithUSDT, setBuyingWithUSDT] = useState(false)
    const [buyingWithUSDC, setBuyingWithUSDC] = useState(false)

    const { usdcBalance, usdtBalance, usdcAllowance, usdtAllowance } = useSaleUserData(id)

    const { execute: usdtApprove } = useContractCall(usdtToken, erc20ABI, "approve", [id, ethers.constants.MaxUint256])
    const { execute: usdcApprove } = useContractCall(usdcToken, erc20ABI, "approve", [id, ethers.constants.MaxUint256])

    const { execute: buyHFGWith } = useSaleBuy(id)

    const saleData = useMemo(() => rounds.find(data => data.address === id), [id]);
    const saleCap = saleData ? Number(typeof saleData?.ocAllotted === 'string' ? saleData.ocAllotted.replace(/,/g, '') : saleData.ocAllotted.toString()) : 0


    const salePrice = saleData ? Number(saleData?.price) : 0;

    const { result: data, isLoading } = useSaleContractData(id)
    
    useEffect(() => {
        if (!isLoading && data) {
            const sale = data
            const totalRaised = parseFloat(formatUnits((sale.totalRaised), 6))
            setSold(totalRaised)
        }
    }, [data, isLoading])


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('id');
        setid(id)
    }, [location.search])

    const approveUSDTCallback = useCallback(async () => {
        try {
            setApprovingUSDT(true)
            const promist = await usdtApprove?.()
            console.log(promist);

            if (promist) {
                await toast.promise(promist(), { loading: "Approving USDT...", success: "USDT Approved", error: "Failed to approve USDT" })
            }
            // toast.success("USDT Approved")
            setApprovingUSDT(false)
        } catch (err) {
            setApprovingUSDT(false)
            toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
        }
    }, [usdtApprove, setApprovingUSDT])

    const approveUSDCCallback = useCallback(async () => {
        try {
            setApprovingUSDC(true)
            const promise = await usdcApprove?.()
            if (promise) {
                await toast.promise(promise(), { loading: "Approving USDC...", success: "USDC Approved", error: "Failed to approve USDC" })
            }
            setApprovingUSDC(false)
            // toast.success("USDC Approved")
        } catch (err) {
            setApprovingUSDC(false)
            toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
        }
    }, [usdcApprove, setApprovingUSDC])

    const buyWithUSDT = useCallback(async () => {
        try {
            setBuyingWithUSDT(true)
            const txn = await buyHFGWith?.(usdtToken, String(usdtInput))
            if (txn) {
                await toast.promise(txn(), { loading: "Buying TMT...", success: "TMT Bought", error: "Failed to buy TMT" })
                // await txn();
                // toast.error("Transaction reverted")
                setUsdtInput(0)
            }
            setBuyingWithUSDT(false)
        } catch (err) {
            toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
            setBuyingWithUSDT(false)
        }
    }, [usdtInput, buyHFGWith])

    const buyWithUSDC = useCallback(async () => {
        try {
            setBuyingWithUSDC(true)
            const txn = await buyHFGWith?.(usdcToken, String(usdcInput))
            if (txn) {
                // console.log(txn)
                await toast.promise(txn(), { loading: "Buying TMT...", success: "TMT Bought", error: "Failed to buy TMT" })
                // await txn();
                // toast.error("Transaction reverted")
                setUsdcInput(0)
            }
            setBuyingWithUSDC(false)
            // setUsdcInput(0)
        } catch (err) {
            toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
            setBuyingWithUSDC(false)
        }
    }, [usdcInput, buyHFGWith])

    const maxUSDT = useCallback(() => {
        const canInvest = (saleCap * salePrice) - sold
        setUsdtInput(canInvest > usdtBalance ? usdtBalance : canInvest)
    }, [usdtBalance, setUsdtInput, saleCap, salePrice, sold])

    const maxUSDC = useCallback(() => {
        const canInvest = (saleCap * salePrice) - sold
        setUsdcInput(canInvest > usdcBalance ? usdcBalance : canInvest)
    }, [usdcBalance, setUsdcInput, saleCap, salePrice, sold])

    const inputUSDT = useCallback((e) => {
        const inputValue = e.target.value;
        const trimmedValue = inputValue.replace(/^0+/, '');

        // Validate if the input is a valid number
        if (/^\d*\.?\d*$/.test(trimmedValue)) {
            // setValue(trimmedValue);
            setUsdtInput(trimmedValue)
        }

    }, [setUsdtInput])

    const inputUSDC = useCallback((e) => {
        const inputValue = e.target.value;
        const trimmedValue = inputValue.replace(/^0+/, '');

        // Validate if the input is a valid number
        if (/^\d*\.?\d*$/.test(trimmedValue)) {
            // setValue(trimmedValue);
            setUsdcInput(trimmedValue)
        }

    }, [setUsdcInput])

    useEffect(() => {
        // calc hfg by usdt
        if (usdtInput) {
            setHfgByUsdt(usdtInput / salePrice)
        } else {
            setHfgByUsdt(0)
        }
    }, [usdtInput, salePrice])

    useEffect(() => {
        // calc hfg by usdt
        if (usdcInput) {
            setHfgByUsdc(usdcInput / salePrice)
        } else {
            setHfgByUsdc(0)
        }
    }, [usdcInput, salePrice])


    return (
        <section className="buyOc">

            <div className="oc_container">
                <div className="title mb-10">
                    <h2 className='mb-4 text-4xl md:text-5xl'>BUY TMT</h2>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-1 gap-4 md:gap-6">
                    <div className="w-full">
                        <div className="buyOcbox">
                            <strong className="topttl">Enter USDT Amount <span>(Available: {usdtBalance} )</span></strong>
                            <input type="number" onInput={inputUSDT} value={usdtInput} placeholder="0.00" />
                            <div className="oc_row align-items-center space-between">
                                <p>You will receive <b>{toLocale(hfgByUsdt)}</b> TMT</p>
                                <span>Min $10</span>
                            </div>
                            <div class="buttonGrp flex flex-wrap gap-2 justify-center mt-7 w-4/5 mx-auto">
                                <button class="oc_btn py-2 px-3" onClick={maxUSDT} >Max Invest</button>
                                {((parseFloat(usdtAllowance) === 0) || (parseFloat(usdtAllowance) < inputUSDT && parseFloat(usdtAllowance) > 9)) && <button class="oc_btn py-2 px-3" disabled={approvingUSDT} onClick={approveUSDTCallback}>
                                    {approvingUSDT ? "Approving..." : "Approve USDT"}
                                </button>}
                                {(parseFloat(usdtAllowance) !== 0 || parseFloat(usdtAllowance) >= inputUSDT) && <button class="oc_btn py-2 px-3" onClick={buyWithUSDT} disabled={buyingWithUSDT}>
                                    {buyingWithUSDT ? "Buying..." : "Buy with USDT"}
                                </button>}
                                {usdtAllowance > 0 ||
                                    <button class="oc_btn py-2 px-3" onClick={() => {
                                        addToken({
                                            data: {
                                                address: usdtToken,
                                                symbol: 'USDT',
                                                decimals: 6,
                                                name: "USDT"
                                            }
                                        })
                                    }}>Add USDT</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BuyOc