import { ethers } from 'ethers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useContractInstance } from './useContract'
import { createPublicClient, formatUnits, http, parseUnits } from 'viem'
import { toast } from "react-hot-toast"
import { erc20ABI, useAccount } from "wagmi"
import SaleABI from "../Assets/Abi/OcSale.json"
import OracleABI from "../Assets/Abi/OcRound.json"
import { dwcToken, oracleAddress, usdcToken, usdtToken } from "../config"


const polygonMumbai = {
    id: 80001,
    name: "Polygon Mumbai",
    network: "maticmum",
    nativeCurrency: {
        name: "POL",
        symbol: "POL",
        decimals: 18,
    },
    rpcUrls: {
        alchemy: {
            http: ["https://polygon-mumbai.g.alchemy.com/v2"],
            webSocket: ["wss://polygon-mumbai.g.alchemy.com/v2"],
        },
        infura: {
            http: ["https://polygon-mumbai.infura.io/v3"],
            webSocket: ["wss://polygon-mumbai.infura.io/ws/v3"],
        },
        default: {
            http: ["https://special-polished-lake.matic-testnet.quiknode.pro/241402282ea6046c7d4516d7ec9610cad44e9838/"],
        },
        public: {
            http: ["https://special-polished-lake.matic-testnet.quiknode.pro/241402282ea6046c7d4516d7ec9610cad44e9838/"],
        },
    },
    blockExplorers: {
        etherscan: {
            name: "PolygonScan",
            url: "https://mumbai.polygonscan.com",
        },
        default: {
            name: "PolygonScan",
            url: "https://mumbai.polygonscan.com",
        },
    },
    contracts: {
        multicall3: {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            blockCreated: 25770160,
        },
    },
    testnet: true,
};

const polygon = {
    id: 137,
    name: "Polygon",
    network: "matic",
    nativeCurrency: {
        name: "POL",
        symbol: "POL",
        decimals: 18,
    },
    rpcUrls: {
        alchemy: {
            http: ["https://polygon-mainnet.g.alchemy.com/v2"],
            webSocket: ["wss://polygon-mainnet.g.alchemy.com/v2"],
        },
        infura: {
            http: ["https://polygon-mainnet.infura.io/v3"],
            webSocket: ["wss://polygon-mainnet.infura.io/ws/v3"],
        },
        default: {
            http: ["https://polygon-rpc.com"],
        },
        public: {
            http: ["https://polygon-rpc.com"],
        },
    },
    blockExplorers: {
        etherscan: {
            name: "PolygonScan",
            url: "https://polygonscan.com",
        },
        default: {
            name: "PolygonScan",
            url: "https://polygonscan.com",
        },
    },
    contracts: {
        multicall3: {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            blockCreated: 25770160,
        },
    },
};



function mapToKey(abi, method, values) {
    const iface = new ethers.utils.Interface(abi);
    const fragment = iface.getFunction(method);

    const outputs = fragment.outputs.map((o) => o.name);
    if (!values) return null
    if (outputs.length === values.length) {
        const encresult = iface.encodeFunctionResult(fragment, values)
        const decresult = iface.decodeFunctionResult(fragment, encresult)
        return decresult;
    }
    return null
}

const initialState = {
    usdcBalance: 0,
    usdtBalance: 0,
    usdcAllowance: 0,
    usdtAllowance: 0,
    dwcBalance: 0,
};



export const useSaleContractData = (saleAddress) => {
    
    const [result, setResult] = useState(null);
    const saleInstance = useContractInstance(saleAddress, SaleABI);

    const fetchSaleInfo = useCallback(async () => {
        if (!saleAddress) {
            setResult(null);
            return;
        }

        try {
            const sale = await saleInstance.sale();
            const _result = mapToKey(SaleABI, "sale", sale);
            setResult(_result);

            if (_result.state === 1) {
                localStorage.setItem("Active", saleAddress);
            }
        } catch (err) {
            console.error(err);
        }
    }, [saleAddress, saleInstance]);

    useEffect(() => {

        const inter = setInterval(() => {
            if (saleAddress) {
                fetchSaleInfo()
            }
        }, 12000)

        return () => clearInterval(inter)
      
    }, [fetchSaleInfo, saleAddress]);

    useEffect(() => {
        if (saleAddress) {
            fetchSaleInfo();
        }
    }, [saleAddress]);


    const memoizedResult = useMemo(() => result, [result]);

    return { result: memoizedResult };
};



export const useSaleUserData = (saleaddress) => {
    const { address } = useAccount()
    const [tokenDetails, setTokenDetails] = useState(initialState);
    const [userInfo, setUserInfo] = useState(null)
    const saleInstance = useContractInstance(oracleAddress, OracleABI)

    const fetchUserInfo = useCallback(async () => {
        try {
            if (!saleaddress) {
                return;
            }

            const _userInfo = await saleInstance.users(address)
            const result = (_userInfo.map((e) => e))
            const _result = mapToKey(SaleABI, "users", result)
            setUserInfo(_result)

        } catch (err) {
            console.error(err)
        }
    }, [saleInstance, address, saleaddress])

    const fetchTokenInfo = useCallback(async () => {
        try {

            if (!saleaddress) {
                return;
            }

            const client = createPublicClient({
                chain: polygon,
                transport: http(),
            });

            const data = await client.multicall({
                contracts: [
                    {
                        address: usdcToken,
                        abi: erc20ABI,
                        functionName: 'balanceOf',
                        args: [address],
                    },
                    {
                        address: usdtToken,
                        abi: erc20ABI,
                        functionName: 'balanceOf',
                        args: [address],
                    },
                    {
                        address: usdcToken,
                        abi: erc20ABI,
                        functionName: 'allowance',
                        args: [address, saleaddress],
                    },

                    {
                        address: usdtToken,
                        abi: erc20ABI,
                        functionName: 'allowance',
                        args: [address, saleaddress],
                    },
                    {
                        address: dwcToken,
                        abi: erc20ABI,
                        functionName: 'balanceOf',
                        args: [address],
                    },

                ],
                allowFailure: false
            })

            if (data?.length > 0) {
                setTokenDetails({
                    usdcBalance: formatUnits(data[0], 6),
                    usdtBalance: formatUnits(data[1], 6),
                    usdcAllowance: formatUnits(data[2], 6),
                    usdtAllowance: formatUnits(data[3], 6),
                    dwcBalance: formatUnits(data[4], 18),
                })
            }
        } catch (error) {
            console.error(error);
        }
    }, [address, saleaddress])

    useEffect(() => {
        const inter = setInterval(() => {
            if (address) {
                fetchUserInfo()
                fetchTokenInfo()
            }
        }, 12000)

        return () => clearInterval(inter)
    }, [fetchUserInfo, fetchTokenInfo, address])

    useEffect(() => {
        if (address) {
            fetchUserInfo()
            fetchTokenInfo()
        }
    }, [address, saleaddress])



    return useMemo(() => {
        return {
            userInfo,
            ...tokenDetails
        }
    }, [userInfo, tokenDetails])
}



export const useContractCall = (contract, abi, method, params) => {
    const { address } = useAccount()
    const contractInstance = useContractInstance(contract, abi, address !== undefined && address !== null)

    const execute = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            try {
                const txn = await contractInstance[method](...params)
                resolve(txn.wait)
            } catch (err) {
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                // reject(null)
                resolve(null)
            }
        })
    }, [contractInstance, method, params])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])
}



export const useReferralStored = () => {
    const { address } = useAccount()
    try {

        useEffect(() => {
            const url = window.location.href;
            const urlParams = new URLSearchParams(new URL(url).search);
            const referrer = urlParams.get('referrer');
          
            if (referrer) {
                localStorage.setItem('referrer', referrer);
                if (address && (referrer.toLowerCase() === address.toLowerCase())) {
                    localStorage.removeItem('referrer')
                }
            }
        }, [address]);
        const referrerFromStorage = localStorage.getItem('referrer');
        return referrerFromStorage ?? ethers.constants.AddressZero;
    } catch (error) {
        console.log(error);
    }
}



export const useSaleBuy = (saleaddress) => {
    const { address } = useAccount()
    const contractInstance = useContractInstance(saleaddress, SaleABI, address !== undefined && address !== null)
    const referrer = useReferralStored()
  
    const execute = useCallback((token, input) => {
        return new Promise(async (resolve, reject) => {
            try {
                const txn = await contractInstance.buyWith(token, referrer, parseUnits(input, 6))
                resolve(txn.wait)
            } catch (err) {
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)
            }
        })
    }, [contractInstance, referrer])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])
}