import React from 'react'

const Sale_card_frame = () => {
    return (
        <div className="absolute top-0 left-0 w-full h-auto -z-10 card-glow">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={'100%'}
                height={'100%'}
                viewBox="0 0 454 181"
                fill="none"
            >
                <path
                    d="M21.7388 20.5967H1V65.5967"
                    stroke="#01A1FE"
                    strokeLinejoin="round"
                />
                <path
                    d="M431.729 19.8711H453V66.3227"
                    stroke="#01A1FE"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.8569 1L47.6817 80.498H131.921L159.107 116.357H294.552L321.738 80.4949H405.977L440.802 1H12.8569Z"
                    fill="url(#paint0_linear_188_316)"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    d="M306.687 100.663H368.87L381.428 84.7371H437.802L452.602 66.04"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    d="M372.428 95.7527H375.587L384.216 84.7676H381.084L372.428 95.7527Z"
                    fill="#01A1FE"
                />
                <path
                    d="M433.061 86.2754L444.961 71.1328H449.146L437.25 86.2754H433.061Z"
                    fill="#01A1FE"
                />
                <path
                    d="M146.97 100.858H147.165V100.468H146.97V100.858ZM84.698 100.663L84.5446 100.783C84.5816 100.83 84.6381 100.858 84.698 100.858V100.663ZM72.1864 84.7355L72.3397 84.6151C72.3027 84.568 72.2462 84.5405 72.1864 84.5405V84.7355ZM16.0171 84.7355L15.863 84.8551C15.8999 84.9027 15.9568 84.9305 16.0171 84.9305V84.7355ZM1.15555 65.1287C1.0906 65.0428 0.968309 65.0258 0.882401 65.0908C0.796494 65.1557 0.779503 65.278 0.844451 65.3639L1.15555 65.1287ZM146.97 100.468H84.698V100.858H146.97V100.468ZM84.8513 100.542L72.3397 84.6151L72.033 84.856L84.5446 100.783L84.8513 100.542ZM72.1864 84.5405H16.0171V84.9305H72.1864V84.5405ZM16.0171 84.7355C16.1712 84.616 16.1711 84.616 16.171 84.6159C16.171 84.6158 16.1708 84.6156 16.1707 84.6154C16.1703 84.6149 16.1698 84.6143 16.1691 84.6134C16.1678 84.6117 16.1657 84.609 16.163 84.6056C16.1576 84.5986 16.1496 84.5882 16.1389 84.5745C16.1176 84.547 16.086 84.5062 16.0445 84.4527C15.9616 84.3458 15.8395 84.1883 15.6827 83.986C15.3691 83.5815 14.9169 82.998 14.3623 82.2819C13.2531 80.8498 11.7344 78.8877 10.0962 76.7676C6.81953 72.5271 3.06593 67.6556 1.15555 65.1287L0.844451 65.3639C2.75631 67.8928 6.51125 72.7659 9.78758 77.0061C11.4259 79.1263 12.9447 81.0885 14.054 82.5207C14.6086 83.2368 15.0609 83.8204 15.3745 84.2249C15.5313 84.4272 15.6534 84.5847 15.7363 84.6917C15.7778 84.7452 15.8095 84.786 15.8308 84.8135C15.8414 84.8272 15.8495 84.8376 15.8549 84.8446C15.8576 84.848 15.8596 84.8507 15.861 84.8524C15.8616 84.8533 15.8621 84.854 15.8625 84.8544C15.8627 84.8546 15.8628 84.8548 15.8629 84.8549C15.863 84.855 15.863 84.8551 16.0171 84.7355Z"
                    fill="#50DBFF"
                />
                <path
                    d="M80.8399 95.7535H77.6797L69.0415 84.7715H72.1834L80.8399 95.7535Z"
                    fill="#01A1FE"
                />
                <path
                    d="M20.2128 86.2754L8.30471 71.1328H4.11914L16.015 86.2754H20.2128Z"
                    fill="#01A1FE"
                />
                <path
                    className='arrow-aniamtion'
                    d="M100.114 85.1936L98.6128 87.1037L101.674 91.0011L98.6128 94.8964L100.114 96.8065L104.663 91.0011L100.114 85.1936Z"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    className='arrow-aniamtion'
                    d="M106.167 85.208L104.667 87.1014L107.728 90.9988L104.667 94.8941L106.154 96.8042L110.716 90.9988L106.167 85.208Z"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    className='arrow-aniamtion'
                    d="M112.214 85.208L110.713 87.1014L113.775 90.9988L110.713 94.8941L112.214 96.8042L116.763 90.9988L112.214 85.208Z"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    className='arrow-animation-alter'
                    d="M351.365 96.8064L352.866 94.8963L349.805 90.9989L352.866 87.1036L351.365 85.1935L346.816 90.9989L351.365 96.8064Z"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    className='arrow-animation-alter'
                    d="M345.315 96.792L346.816 94.8986L343.755 91.0012L346.816 87.1059L345.328 85.1958L340.766 91.0012L345.315 96.792Z"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    className='arrow-animation-alter'
                    d="M339.265 96.792L340.766 94.8986L337.704 91.0012L340.766 87.1059L339.265 85.1958L334.716 91.0012L339.265 96.792Z"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    d="M39.2856 84.7964V130.539"
                    stroke="#01A1FE"
                    strokeWidth="0.5"
                    strokeLinejoin="round"
                />
                <path
                    d="M414.711 85.0823V131.26"
                    stroke="#01A1FE"
                    strokeWidth="0.5"
                    strokeLinejoin="round"
                />
                <path
                    d="M158.644 165.398H345.103L355.543 149.566H402.408L414.712 130.979"
                    stroke="#50DBFF"
                    strokeWidth="0.39"
                    strokeLinejoin="round"
                />
                <path
                    d="M348.061 160.517H350.687L357.861 149.597H355.257L348.061 160.517Z"
                    fill="#01A1FE"
                />
                <path
                    d="M398.465 151.096L408.358 136.042H411.837L401.948 151.096H398.465Z"
                    fill="#01A1FE"
                />
                <path
                    d="M160.634 165.593H160.829V165.203H160.634V165.593ZM108.865 165.398L108.702 165.505C108.739 165.56 108.8 165.593 108.865 165.593V165.398ZM98.4643 149.565L98.6273 149.458C98.5912 149.403 98.53 149.37 98.4643 149.37V149.565ZM51.7696 149.565L51.606 149.671C51.642 149.726 51.7036 149.76 51.7696 149.76V149.565ZM39.4505 130.086C39.3929 129.995 39.2725 129.968 39.1814 130.026C39.0904 130.083 39.0633 130.204 39.1208 130.295L39.4505 130.086ZM160.634 165.203H108.865V165.593H160.634V165.203ZM109.028 165.291L98.6273 149.458L98.3013 149.672L108.702 165.505L109.028 165.291ZM98.4643 149.37H51.7696V149.76H98.4643V149.37ZM51.7696 149.565C51.9332 149.459 51.9332 149.459 51.9331 149.459C51.9331 149.458 51.933 149.458 51.9328 149.458C51.9325 149.458 51.9321 149.457 51.9316 149.456C51.9304 149.454 51.9287 149.452 51.9265 149.448C51.922 149.441 51.9153 149.431 51.9065 149.417C51.8888 149.39 51.8624 149.349 51.828 149.296C51.759 149.19 51.6575 149.033 51.5272 148.832C51.2665 148.43 50.8906 147.85 50.4295 147.138C49.5074 145.715 48.2449 143.764 46.883 141.657C44.159 137.441 41.0386 132.598 39.4505 130.086L39.1208 130.295C40.7102 132.809 43.8317 137.653 46.5554 141.868C47.9174 143.976 49.18 145.927 50.1022 147.35C50.5633 148.062 50.9392 148.642 51.1999 149.045C51.3303 149.246 51.4318 149.402 51.5007 149.509C51.5352 149.562 51.5615 149.602 51.5792 149.63C51.5881 149.643 51.5948 149.654 51.5993 149.66C51.6015 149.664 51.6032 149.667 51.6043 149.668C51.6049 149.669 51.6053 149.67 51.6056 149.67C51.6058 149.67 51.6059 149.671 51.6059 149.671C51.606 149.671 51.606 149.671 51.7696 149.565Z"
                    fill="#50DBFF"
                />
                <path
                    d="M105.659 160.518H103.032L95.8506 149.601H98.4625L105.659 160.518Z"
                    fill="#01A1FE"
                />
                <path
                    d="M55.2579 151.096L45.3585 136.042H41.8789L51.7682 151.096H55.2579Z"
                    fill="#01A1FE"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_188_316"
                        x1="226.829"
                        y1="142.505"
                        x2="226.829"
                        y2={1}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#002432" />
                        <stop offset={1} stopColor="#1C3F5E" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    )
}

export default Sale_card_frame
