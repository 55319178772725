import PublicSale from '../../Components/PublicSale/PublicSale'
import Projects from '../../Components/Projects/Projects'
import Faqs from '../../Components/Faqs/Faqs'
import SaleDetails from '../../Components/SaleDetailList/SaleDetailList'

const Sale = () => {

    return (
        <>
            <PublicSale />
            <SaleDetails />
            <Projects />
            <Faqs />
        </>
    )
}

export default Sale