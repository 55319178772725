export const rounds = [
    {
        address: "0xe17abcfbcd9c8f5b6a90d997bd948ccf4a7cd570",
        price: 0.03,
        startDate: "30 September 2024",
        ocAllotted: 49999.99666667,
        startTime: 1727634600000
    },
    {
        address: "0xcd44efaa25b53b725dfd76206826228171e4afc6",
        price: 0.06,
        startDate: "30 October 2024",
        ocAllotted: 49999.99666667,
        startTime: 1730226600000
    },
    {
        address: "0x7961a020b853d335E2551197E9A06DcDb03b2218",
        price: 0.09,
        startDate: "30 November 2024",
        ocAllotted: 49999.99666667,
        startTime: 1732905000000
    },
]