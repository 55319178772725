import React from 'react'
import './Faqs.css'
import Faq from "react-faq-component";
import AnimationComponent from '../AnimationComponent';

const data = {
    rows: [
        {
            title: "Which Wallet Should I Use?",
            content: `Techmont supports all major crypto wallets. We recommend using Trust Wallet or Metamask Wallet for a seamless experience.`,
        },
        {
            title: "When Can I Buy TMT?",
            content: `The Techmont ITO begins on 30th September 2024. Mark your calendar and get ready to invest in the future of Blockchain!`,
        },
        {
            title: "When Can I Claim My TMT?",
            content: `If you have purchased TMT tokens during the ITO, you may claim them as follows: 30% after 3 months, 30% after 6 months, and the remaining 40% after 9 months of purchase. Stay tuned for further instructions on the claiming process.`,
        },
        {
            title: "I Can't Buy TMT",
            content: `Ensure you are connected to the Polygon Chain via Metamask, Trust Wallet, or any wallet that supports Polygon. Also, verify that you have sufficient POL to cover gas fees.`,
        },
        {
            title: "Will I Get a Referral Bonus for Referring Users to Buy TMT?",
            content: `Yes, you will earn a referral bonus when someone uses your referral link to purchase TMT during any of the ITO sale rounds.`,
        },
    ],
};

const styles = {
    bgColor: 'transparent',
    titleTextColor: "#50dbff",
    rowTitleColor: "#ffffff",
    rowContentColor: 'grey',
    arrowColor: "#50dbff",
};

const config = {
    animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};
const Faqs = () => {
    return (
        <section className="faqs">
            <div className="oc_container">
                <div className='titlebox mx-auto text-center'>
                    {/* <AnimationComponent x={"100"} duration={1.2} scale={0}>
                        <div className="absolute top-0 sm:top-[10px] lg:top-0 right-0">
                            <img src="images/titleframe.svg" alt="" width={""} height={""} className="w-[20vw] 2xl:w-[18vw] block hue-rotate-[40deg] blinkers" />
                        </div>
                    </AnimationComponent>
                    <AnimationComponent x={"-100"} duration={1.2} scale={0}>
                        <div className="absolute top-0 sm:top-[10px] lg:top-0 left-0 rotate-180">
                            <img src="images/titleframe.svg" alt="" width={""} height={""} className="w-[20vw] 2xl:w-[18vw] block hue-rotate-[40deg] blinkers" />
                        </div>
                    </AnimationComponent> */}
                    <AnimationComponent y={"100"} duration={1} scale={0}>
                        <h6 className='text-secondary mb-2'>FAQ's</h6>
                    </AnimationComponent>
                    <AnimationComponent y={"100"} duration={1} delay={0.5}>
                        <h2 className='text-4xl	md:text-5xlF mb-2'>Quick Questions</h2>
                    </AnimationComponent>
                    <AnimationComponent y={"100"} duration={1} delay={0.7}>
                        <p className='mb-5'>Here are Some Important things you should know!.</p>
                    </AnimationComponent>
                </div>
                <AnimationComponent y={"100"} duration={1}>
                    <div className='max-w-3xl mx-auto'>
                        <Faq
                            data={data}
                            styles={styles}
                            config={config}
                        />
                    </div>
                </AnimationComponent>
            </div>
        </section>
    )
}

export default Faqs