
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Pages/Layout";
import Sale from "./Pages/Sale/Sale";
import SaleDetails from "./Pages/SaleDetails/SaleDetails";
import Ref from "./Pages/RefDetails/RefDetails";
import { Toaster } from "react-hot-toast";
import './index.css'


function App() {
  return (
    <BrowserRouter>
<Toaster position="top-center"/>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Sale />} />
          <Route path="saledetails" element={<SaleDetails />} />
          <Route path="referdetails" element={<Ref />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;


