import { useEffect, useMemo, useState } from "react"
import { useSaleUserData } from "./useContractData"
import { formatUnits } from "ethers/lib/utils"
import { useAccount } from "wagmi"

export const useSaleEligible = (saleaddress) => {
    const { address } = useAccount()
    const { userInfo } = useSaleUserData(saleaddress)
    const [eligible, setEligible] = useState(false)

    useEffect(() => {
        const inter = setInterval(() => {
            if (address && userInfo) {
                const totalInvested = parseFloat(formatUnits(userInfo.totalSpent, 6))
                setEligible(totalInvested > 0)
            }
        }, 12000)

        return () => clearInterval(inter)
    }, [userInfo, address])

    useEffect(() => {
        if (address && userInfo) {
            const totalInvested = parseFloat(formatUnits(userInfo.totalSpent, 6))
            setEligible(totalInvested > 0)
        }
    }, [address, userInfo])

    return useMemo(() => {
        return {
            eligible
        }
    }, [eligible])
}