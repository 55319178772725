import React from 'react'

const Button_Components = ({ fun, text }) => {
    return (
        <>
            {/* <button className='relative flex justify-center items-center font-semibold primary-btn w-32 rounded-lg text-base hover:text-[#0ff] ease-in-out duration-100 btn-main active:translate-y-2' onClick={fun}>
                <div className='absolute btnsvgbox'>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={'100%'}
                        height={'100%'}
                        viewBox="0 0 1920 1080"
                        fill="none"
                        className='w-full h-full'
                    >
                        <path
                            d="M166.76 429.3C166.76 429.3 39.91 425.11 32.08 430.5C24.25 435.89 123.14 827.6 134.1 840.41C145.06 853.22 1677.6 838.58 1693.42 838.58C1709.24 838.58 1674.48 772.97 1674.48 772.97L844.61 765.49L767.26 715.95L262.34 696.86L166.76 429.31V429.3Z"
                            fill="url(#paint0_linear_201_2)"
                        />
                        <path
                            d="M212.92 731.83C225.12 731.83 739.73 724.07 749.7 732.07C758.89 739.44 792.9 789.14 805.11 795.39C817.31 801.65 1875.09 801.34 1887.43 792.02C1900.57 782.1 1769.53 242.92 1748.72 228.31C1736 219.38 126.19 220.93 109.51 229.71C93.34 238.22 200.72 731.82 212.93 731.82L212.92 731.83ZM176.99 298.38C187.06 291.06 703.47 289.58 709.87 293.24C716.28 296.9 769.36 409.47 784 409.47C798.64 409.47 1731.96 396.9 1737.45 400.56C1742.94 404.22 1821.53 742.6 1812.69 746.27C1803.85 749.93 834.34 758.17 829.97 749.93C825.6 741.69 773.94 683.93 766.62 681.49C759.3 679.05 278.81 691.23 275.28 678.74C270.71 662.57 166.93 305.7 177 298.38H176.99Z"
                            fill="url(#paint1_linear_201_2)"
                        />
                        <path
                            d="M1694.53 257.45C1699.66 260.83 1730.4 360.66 1727.65 367.99C1724.9 375.31 811.46 375.62 802.31 370.13C793.16 364.64 750.14 263.87 750.14 255.63C750.14 247.39 1684.91 251.13 1694.52 257.46L1694.53 257.45Z"
                            fill="#080610"
                        />
                        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
                            <path
                                d="M1724.6 350.4C1724.6 350.4 850.77 361.05 821.53 359.14C792.29 357.24 757.35 254.18 757.35 254.18L750.15 255.62C750.15 255.62 792.25 369.82 803.84 375.92C815.43 382.02 1723.99 372.55 1727.65 370.11C1731.31 367.67 1724.6 350.4 1724.6 350.4Z"
                                fill="#742F6B"
                            />
                        </g>
                        <path
                            d="M1711.31 294.36C1711.31 294.36 1697.27 266.91 1689.34 263.86C1681.41 260.81 750.14 255.62 750.14 255.62C750.14 255.62 907.62 247.2 1112.56 250.88C1317.49 254.56 1688.73 253.05 1693.61 254.18C1698.49 255.31 1711.31 294.36 1711.31 294.36Z"
                            fill="#232740"
                        />
                        <path
                            d="M1746.52 419.37C1741.12 405.19 785.83 416.8 775.76 414.97C765.69 413.14 719 308.3 708.03 299.65C697.07 291.01 205.7 302.56 195.43 306C185.16 309.44 189.77 368.13 189.77 368.13C189.77 368.13 172.63 299.06 176.29 294.48C179.95 289.9 706.2 290.16 711.23 291.47C716.26 292.78 768.89 400.32 782.62 405.35C796.35 410.38 1730.96 395.76 1737.44 400.56C1743.93 405.35 1746.51 419.36 1746.51 419.36L1746.52 419.37Z"
                            fill="#232740"
                        />
                        <path
                            d="M1677.93 838.71L1658.43 832.29C1658.43 832.29 752.73 824.05 744.12 824.05C735.51 824.05 698.9 771.88 698.9 771.88C698.9 771.88 172.36 772.49 169.91 771.88C167.47 771.27 168.08 784.08 168.08 784.08L744.12 852.41L1677.93 838.69V838.71Z"
                            fill="#13C4FF"
                        />
                        <path
                            d="M1677.93 838.71C1677.93 838.71 751.52 839.63 744.12 838.71C736.72 837.79 695.61 784.09 688.71 784.09C681.81 784.09 176.62 779.21 168.08 784.09C159.54 788.97 194.93 867.44 209.57 872.33C224.21 877.21 1676.74 871.95 1684.06 869.51C1691.38 867.07 1677.93 838.71 1677.93 838.71Z"
                            fill="url(#paint2_linear_201_2)"
                        />
                        <path
                            d="M1877.72 793.37C1877.72 793.37 1827.63 771.81 1745.88 757.82C1664.12 743.83 825.8 738.7 818.48 753.47C811.16 768.24 805.11 795.4 805.11 795.4C805.11 795.4 1753.31 797.78 1877.72 793.38V793.37Z"
                            fill="#232740"
                        />
                        <path
                            d="M738.53 730.47C751.06 726.14 759.3 688.29 759.3 688.29C759.3 688.29 275.16 690.44 262.35 696.85C249.54 703.26 224.83 725.22 224.83 725.22L738.54 730.47H738.53Z"
                            fill="#232740"
                        />
                        <path
                            d="M1684.05 869.51C1684.05 869.51 1246.89 852.43 849.34 852.43C451.79 852.43 209.56 872.33 209.56 872.33C209.56 872.33 1071.27 892.09 1684.05 869.51Z"
                            fill="#232740"
                        />
                        <path
                            d="M291.22 694.47C291.22 694.47 259.6 687.66 254.72 688.29C249.84 688.92 231.62 719.37 231.62 719.37C231.62 719.37 270.38 710.4 291.23 694.48L291.22 694.47Z"
                            fill="#343956"
                        />
                        <path
                            d="M853.82 749.02C863.63 753.59 810.23 775.89 810.23 775.89C810.23 775.89 803.95 743.51 805.11 739.4C806.27 735.29 853.81 749.02 853.81 749.02H853.82Z"
                            fill="#343956"
                        />
                        <path
                            d="M1852.5 781.93C1852.5 781.93 1805.39 760.58 1793.01 757.82C1780.63 755.07 1740.81 757.09 1740.81 757.09C1740.81 757.09 1814.98 782.81 1852.5 781.93Z"
                            fill="#343956"
                        />
                        <path
                            d="M503.37 857.7L283.39 866.38C283.39 866.38 326.77 848.32 503.37 857.7Z"
                            fill="#343956"
                        />
                        <g style={{ mixBlendMode: "multiply" }} opacity="0.3">
                            <path
                                d="M1881.33 781.93C1881.33 781.93 1829.16 726.14 1820.93 706C1812.69 685.86 1769.8 387.98 1769.8 387.98C1769.8 387.98 1837.41 538.45 1881.34 781.93H1881.33Z"
                                fill="#742F6B"
                            />
                        </g>
                        <g style={{ mixBlendMode: "multiply" }} opacity="0.3">
                            <path
                                d="M119.57 244.74C119.57 244.74 163.5 285.79 171.74 316.06C179.98 346.33 255.02 648.35 254.11 662.99C253.19 677.63 214.76 719.37 214.76 719.37C214.76 719.37 117.44 385.08 119.58 244.74H119.57Z"
                                fill="#742F6B"
                            />
                        </g>
                        <path
                            d="M1042.31 413.64C1042.31 413.64 966.13 410.53 880.71 406.34C795.29 402.15 775.77 414.96 775.77 414.96C775.77 414.96 890.91 423.66 1042.31 413.64Z"
                            fill="#62687B"
                        />
                        <path
                            d="M562.51 296.77C562.51 296.77 418.22 289.74 310.85 291.32C203.48 292.9 176.31 294.48 176.31 294.48C176.31 294.48 180.54 299.84 199.02 305.54C217.5 311.24 562.51 296.77 562.51 296.77Z"
                            fill="#343956"
                        />
                        <path
                            d="M1693.61 254.18C1697.66 254.68 1687.63 263.66 1687.63 263.66L1451.49 254.18C1451.49 254.18 1646.23 248.37 1693.61 254.18Z"
                            fill="#343956"
                        />
                        <path
                            d="M227.26 725.24C227.26 725.24 245.9 722.98 248.79 714.49C251.67 706 254.72 688.29 254.72 688.29L227.26 725.25V725.24Z"
                            fill="#62687B"
                        />
                        <path
                            d="M1858.28 786.06C1858.28 786.06 1843.2 763.27 1837.1 757.82C1831 752.37 1794.7 756.37 1794.7 756.37L1858.29 786.06H1858.28Z"
                            fill="#62687B"
                        />
                        <g style={{ mixBlendMode: "soft-light" }} opacity="0.5">
                            <path
                                d="M654.96 244.74C684.13 254.92 397.48 295.91 307.18 272.16C216.88 248.4 602.48 226.44 654.96 244.74Z"
                                fill="#AEB1BB"
                            />
                            <path
                                d="M709.8 832.22C758.08 823.57 613.65 777.95 506.39 786.66C399.13 795.37 560.54 858.95 709.8 832.22Z"
                                fill="#AEB1BB"
                            />
                        </g>
                        <g style={{ mixBlendMode: "soft-light" }}>
                            <path
                                d="M738.53 744.57C761.41 745.3 400.56 768.77 291.22 775.89C181.88 783 135.78 757.82 135.78 757.82C135.78 757.82 189.12 731.44 416.09 730.47C643.06 729.49 738.52 744.58 738.52 744.58L738.53 744.57Z"
                                fill="#FFE683"
                            />
                        </g>
                        <g style={{ mixBlendMode: "soft-light" }}>
                            <path
                                d="M1687.29 799.63C1687.29 799.63 1602.39 830.98 1242.79 828.13C883.19 825.28 758.9 813.97 758.9 813.97C758.9 813.97 1310.21 788.32 1687.29 799.63Z"
                                fill="#13C4FF"
                            />
                        </g>
                        <g style={{ mixBlendMode: "soft-light" }}>
                            <path
                                d="M130.9 428.57C143.94 433.34 174.79 584.84 173.57 636.88C172.35 688.92 93.4101 648.33 67.4101 567.62C41.4101 486.91 43.6101 440.59 50.9401 436.32C58.2601 432.05 130.9 428.57 130.9 428.57Z"
                                fill="#FFE683"
                            />
                        </g>
                        <defs>
                            <linearGradient
                                id="paint0_linear_201_2"
                                x1="856.96"
                                y1="414.26"
                                x2="880.28"
                                y2="1081.19"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#00FFFF" />
                                <stop offset={1} stopColor="#13C4FF" />
                            </linearGradient>
                            <linearGradient
                                id="paint1_linear_201_2"
                                x1="998.1"
                                y1="222.34"
                                x2="998.1"
                                y2="799.64"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#0C0515" />
                                <stop offset={1} stopColor="#1D1B31" />
                            </linearGradient>
                            <linearGradient
                                id="paint2_linear_201_2"
                                x1="926.5"
                                y1="781.93"
                                x2="926.5"
                                y2="874.34"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#0C0515" />
                                <stop offset={1} stopColor="#1D1B31" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                {text}
            </button> */}
            <button className="button" onClick={fun}>
                <div class="button-overlay"></div>
                <span>{text}</span>
            </button>
        </>
        
    )
}

export default Button_Components
