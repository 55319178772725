import React from 'react'
import { FaTelegramPlane, FaRegEnvelope, FaTwitter, FaMediumM, FaInstagram, FaRedditAlien, FaFacebookF, FaWhatsapp, FaTelegram } from "react-icons/fa";
import './Footer.css'
import AnimationComponent from '../AnimationComponent';
const Footer = () => {
    return (
        <AnimationComponent y={'100'} duration={1} delay={0.6}>
            <footer className='relative w-full overflow-hidden pt-6 md:pt-10 pb-4 md:pb-5 bg-primary border-t-2 border-secondary-100 rounded-t-3xl'>
                {/* <div className='header-Head absolute bottom-0 left-0 w-full rotate-180'>
                    <Header_head />
                </div> */}
                <div className='w-full' >
                    <div className='absolute w-full  h-full top-0 left-0 hidden z-10' style={{ transform: 'rotateX(180deg)' }}>
                        <div className='header_component_uper w-full left-[-0%] top-[0%] md:w-[90%] md:left-[5%] md:top-[0%] lg:w-[80%] lg:left-[10%] lg:top-[0%] xl:w-[80%] xl:left-[10%] xl:top-[0%]'></div>
                        <div className='header_component_center w-[72%] sm:w-2/4 sm:left-[25%] left-[14%] top-[0%] md:w-[40%] md:left-[30%] md:top-[0%] lg:w-[30%] lg:left-[35%] lg:top-[0%] xl:w-[28%] xl:left-[36.5%] xl:top-[0]'></div>
                        <div className='header_component_border_inner bg-black left-2/4 sm:w-[0%] sm:top-[0%] sm:left-[5%] md:w-[0%] md:top-[0%] md:left-[5%] lg:w-[90%] lg:top-[0%] lg:left-[5%] xl:w-[90%] xl:left-[5%] xl:top-[0%]'></div>
                        <div className='header_component_border_outer bg-black w-[93%] top-[45%] left-[3.5%] md:w-[80%] md:top-[50%] md:left-[10%] lg:w-[74%] lg:top-[47%] lg:left-[13%] xl:w-[74%] xl:left-[13%] xl:top-[47%]'></div>
                        {/* <img className='absolute bottom-[31%] right-[25%] w-[150px]' src='images/arrow-frame.svg' alt='arrow-frame' /> */}
                        {/* <img className='absolute bottom-[31%] left-[25%] w-[150px] rotate-y-[180deg]' src='images/arrow-frame.svg' alt='arrow-frame' /> */}
                    </div>
                    {/* <div className='header-Head absolute top-0 left-0 h-48 w-full'>
                            <Header_head />
                            <img src='images/hedd.svg' className='w-full h-full'/>
                        </div> */}
                    {/* <div>
                        <span className='balls hidden sm:block sm:absolute sm:top-[37%]  md:top-[34%] sm:left-[5%] md:left-[10%] lg:top-[36%]   w-3 h-3 lg:w-4 lg:h-4 rounded-full lg:left-[15%] z-10'></span>
                        <span className='balls hidden sm:block sm:absolute sm:top-[37%]  md:top-[34%] sm:left-[8%] md:left-[12%] lg:top-[36%] w-3 h-3 lg:w-4 lg:h-4 rounded-full lg:left-[17%] z-10'></span>
                        <span className='balls hidden sm:block sm:absolute sm:top-[37%]  md:top-[34%] sm:left-[11%] md:left-[14%] lg:top-[36%]   w-3 h-3 lg:w-4 lg:h-4 rounded-full lg:left-[19%] z-10'></span>
                    </div> */}
                    {/* <div>
                        <span className='balls hidden sm:block sm:absolute sm:top-[37%] md:top-[34%] sm:right-[5%] md:right-[10%] lg:top-[36%] w-3 h-3 lg:w-4 lg:h-4 rounded-full lg:right-[15%] z-10'></span>
                        <span className='balls hidden sm:block sm:absolute sm:top-[37%] md:top-[34%] sm:right-[8%] md:right-[12%] lg:top-[36%]  w-3 h-3 lg:w-4 lg:h-4 rounded-full lg:right-[17%] z-10'></span>
                        <span className='balls hidden sm:block sm:absolute sm:top-[37%] md:top-[34%] sm:right-[11%] md:right-[14%] lg:top-[36%]  w-3 h-3 lg:w-4 lg:h-4 rounded-full lg:right-[19%] z-10'></span>
                    </div> */}
                    {/* <div>
                        <img src="images/arrow.svg" alt="" className='absolute top-[35%] md:top-[33%] w-4 md:w-4 left-[5%] sm:left-[14%] md:left-[20%] lg:top-[35%] lg:left-[27%] lg:w-5 z-10 arrow-aniamtion' />
                        <img src="images/arrow.svg" alt="" className='absolute top-[35%] md:top-[33%] w-4 md:w-4 left-[7%] sm:left-[16%] md:left-[22%] lg:top-[35%] lg:left-[28%] lg:w-5 z-10 arrow-aniamtion' />
                        <img src="images/arrow.svg" alt="" className='absolute top-[35%] md:top-[33%] w-4 md:w-4 left-[9%] sm:left-[18%] md:left-[24%] lg:top-[35%] lg:left-[29%] lg:w-5 z-10 arrow-aniamtion' />
                        <div>
                            <img src="images/arrow.svg" alt="" className='absolute top-[35%] md:top-[33%] w-4 md:w-4 right-[5%] sm:right-[14%] md:right-[20%] lg:top-[35%] lg:right-[27%] lg:w-5 z-10 arrow-animation-alter head' />
                            <img src="images/arrow.svg" alt="" className='absolute top-[35%] md:top-[33%] w-4 md:w-4 right-[7%] sm:right-[16%] md:right-[22%] lg:top-[35%] lg:right-[28%] lg:w-5 z-10 arrow-animation-alter head' />
                            <img src="images/arrow.svg" alt="" className='absolute top-[35%] md:top-[33%] w-4 md:w-4 right-[9%] sm:right-[18%] md:right-[24%] lg:top-[35%] lg:right-[29%] lg:w-5 z-10 arrow-animation-alter head' />
                        </div>
                    </div> */}
                </div>
                <div className="socialIcons relative z-10">
                    {/* <div className="title">
                    <h2>Easy Connect With Your Platform</h2>
                    </div> */}
                    <ul>
                        <li>
                            {/* ....... */}
                            <AnimationComponent y={"100"} duration={0.8} delay={2.3}>
                                <a href="https://t.me/TechMontGroup" rel="noreferrer" title="Telegram Group" target="_blank">
                                    <FaTelegramPlane />
                                </a>
                            </AnimationComponent>
                        </li>
                        <li>
                            <AnimationComponent y={"100"} duration={0.8} delay={2.3}>
                                <a href="https://www.whatsapp.com/channel/0029VagCDyL1dAvwq5Up603H" rel="noreferrer" title="WhatsApp Channel" target="_blank">
                                    <FaWhatsapp />
                                </a>
                            </AnimationComponent>
                        </li>
                        <li>
                            <AnimationComponent y={"100"} duration={0.8} delay={2.3}>
                                <a href="https://x.com/Tech_Mont" rel="noreferrer" title="Twitter" target="_blank">
                                    <FaTwitter />
                                </a>
                            </AnimationComponent>
                        </li>
                        {/* <li>
                            <AnimationComponent y={"100"} duration={0.8} delay={2.3}>
                                <a href="https://t.me/TechMontChannel" rel="noreferrer" title="Telegram Channel" target="_blank">
                                    <FaTelegramPlane />
                                </a>
                            </AnimationComponent>
                        </li> */}
                        <li>
                            <AnimationComponent y={"100"} duration={0.8} delay={2.3}>
                                <a href="https://medium.com/@Tech_Mont" rel="noreferrer" title="Medium" target="_blank">
                                    <FaMediumM />
                                </a>
                            </AnimationComponent>
                        </li>
                        <li>
                            {/* ....... */}
                            <AnimationComponent y={"100"} duration={0.8} delay={2.3}>
                                <a href="https://www.instagram.com/techmont9 " rel="noreferrer" title="Instagram" target="_blank">
                                    <FaInstagram />
                                </a>
                            </AnimationComponent>
                        </li>
                        {/* <li>
                            <AnimationComponent y={"100"} duration={0.8} delay={2.3}>
                                <a href="https://t.me/TechMontCommunity" rel="noreferrer" title="Telegram Community" target="_blank">
                                    <FaTelegramPlane />
                                </a>
                            </AnimationComponent>
                        </li> */}
                        <li>
                            <AnimationComponent y={"100"} duration={0.8} delay={2.3}>
                                <a href="https://www.facebook.com/TechMont" rel="noreferrer" title="Facebook" target="_blank">
                                    <FaFacebookF />
                                </a>
                            </AnimationComponent>
                        </li>
                    </ul>
                </div>
                <div className="copyright relative mt-8 z-10">
                    <AnimationComponent y={"100"} duration={0.8} delay={2.8}>
                        <p>©  All Rights Reserved</p>
                    </AnimationComponent>
                </div>
            </footer>
        </AnimationComponent>
    )
}

export default Footer