import './SaleDetailList.css'
import { rounds } from '../../config/rounds';
import AnimationComponent from '../AnimationComponent';

const SaleDetailList = () => {

    return (
        <>
            <section className="section-padding pt-0">
                <div className="container relative">
                    {/* <AnimationComponent x={"100"} duration={1.2} scale={0}>
                        <div className="absolute top-0 right-0">
                            <img src="images/titleframe.svg" alt="" width={""} height={I Can't Buy TMT""} className="w-[16vw] sm:w-[20vw] 2xl:w-[10vw] block  blinkers" />
                        </div>
                    </AnimationComponent> */}
                    {/* <AnimationComponent x={"-100"} duration={1.2} scale={0}>
                        <div className="absolute top-0 left-0 rotate-180">
                            <img src="images/titleframe.svg" alt="" width={""} height={""} className="w-[16vw] sm:w-[20vw] 2xl:w-[10vw] block  blinkers" />
                        </div>
                    </AnimationComponent> */}
                    <AnimationComponent y={"100"} duration={1.2}>
                        <div className="title">
                            <h2 className='text-4xl	md:text-5xl mb-10'>Sale Details </h2>
                        </div>
                    </AnimationComponent>
                    <AnimationComponent y={"150"} duration={1.2} delay={0.2}>
                        <div className="saleDetailsList max-w-4xl mx-auto">
                            <div className='table-responsive'>
                                <table width="100%">
                                    <thead>
                                        <tr>
                                            <th>Round</th>
                                            <th>Date</th>
                                            <th>TMT Quantity</th>
                                            <th>Price ($)</th>
                                            <th>Duration</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            rounds.map((value, idx) =>
                                                <>
                                                <tr class="spacer">
                                                    <td colspan="5"></td>
                                                </tr>
                                                <tr>
                                                    <td>{idx + 1}.</td>
                                                    <td>{value.startDate}</td>
                                                    <td>{value.ocAllotted.toLocaleString()}</td>
                                                    <td>${value.price}</td>
                                                    <td>30 Days</td>
                                                </tr>
                                                </>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </AnimationComponent>
                </div>
            </section>

        </>

    )
}

export default SaleDetailList