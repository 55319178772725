import { useCallback, useEffect, useMemo, useState } from "react";
import { graphQlUrl } from "../config";
import axios from "axios";
import { useAccount } from "wagmi";
import { formatEther, formatUnits } from "viem";
import { toLocale } from "../config/helpers";

export const useGraphData = () => {
    const [data, setData] = useState([])
    const [globalData, setGlobaldata] = useState({ hfgSold: 0, usdRaised: 0, totalBonus: 0 })

    const { address } = useAccount()

    const fetchGraphData = useCallback(async () => {
        try {
            if (!address) return

            const options = {
                method: 'POST',
                url: graphQlUrl,
                headers: {
                    'content-type': 'application/json'
                },
                data: {
                    query: `{
                        hfReferrals(where:{referrer:"${address}"} orderBy:level, orderDirection: asc) {
                            referredUser
                            level
                            amount
                            hfgBonus
                        }

                        hficoSales {
                            usdtRaised
                            usdcRaised
                            hfgSold
                          }

                          hfReferrers(where:{id:"${address}"}) {
                            totalHFBonus
                        }
                    }`
                }
            };
            const response = await axios.request(options);
            const res = response.data;

            const { hfReferrals, hficoSales, hfReferrers } = res.data
            const hfgSold = hficoSales[0]?.hfgSold ?? 0;
            const usdcRaised = hficoSales[0]?.usdcRaised ?? 0;
            const usdtRaised = hficoSales[0]?.usdtRaised ?? 0;
            const totalHFBonus = hfReferrers[0]?.totalHFBonus ?? 0;
            
            setData(hfReferrals)
            setGlobaldata({ hfgSold: toLocale(formatEther(hfgSold)), usdRaised: toLocale(parseInt(formatUnits(usdcRaised, 6)) + parseInt(formatUnits(usdtRaised, 6))), totalBonus: toLocale(formatEther(totalHFBonus)) })

        } catch (err) {
            console.error(err)
        }
    }, [address])

    useEffect(() => {
        const inter = setInterval(() => {
            fetchGraphData()
        }, 12000);

        return () => clearInterval(inter)
    }, [fetchGraphData])

    useEffect(() => {
        fetchGraphData()
    }, [fetchGraphData])

    return useMemo(() => {
        return {
            data,
            ...globalData
        }
    }, [data, globalData])
}


