import './Projects.css'
import { rounds } from "../../config/rounds";
import { RoundPage } from "./RoundPage";
import AnimationComponent from "../AnimationComponent";
import SaleCard from "./SaleCard";
import { useGraphData } from '../../hooks/useGraph';
import techmontCoin from '../../Assets/images/techmont-coin.png'
import raisedIcon from '../../Assets/images/raised-icon.png'
import roundLeftIocn from '../../Assets/images/round-left-icon.png'

const Projects = () => {

    const { hfgSold, usdRaised } = useGraphData()

    return (
        <section className="projects">
            <div className="container">
                <div className="relative mb-10 overflow-hidden">
                    {/* <AnimationComponent x={"100"} duration={1.2} scale={0}>
                        <div className="absolute top-3 lg:top-0 -right-8 sm:right-0">
                            <img src="images/titleframe.svg" alt="" width={""} height={""} className="w-[20vw] 2xl:w-[18vw] block hue-rotate-[40deg] blinkers" />
                        </div>
                    </AnimationComponent>
                    <AnimationComponent x={"-100"} duration={1.2} scale={0}>
                        <div className="absolute top-3 lg:top-0 -left-8 sm:left-0 rotate-180">
                            <img src="images/titleframe.svg" alt="" width={""} height={""} className="w-[20vw] 2xl:w-[18vw] block hue-rotate-[40deg] blinkers" />
                        </div>
                    </AnimationComponent> */}
                    <AnimationComponent y={"100"} duration={1.2} delay={0.2}>
                        <div className="title text-center mb-5">
                            <h2 className='text-4xl	md:text-5xl'>Sale Statistics</h2>
                            <p>Overall statistics for TMT Sale</p>
                        </div>
                    </AnimationComponent>
                </div>
                <div className="mb-10 lg:mb-14">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-5 gap-x-5 md:gap-x-8 justify-center countBox">
                        <div className="relative">
                            <AnimationComponent y={'100'} duration={1} delay={0.8}>
                                <SaleCard
                                    tittle={'Total TMT Sold'}
                                    img={techmontCoin}
                                    img_width={"36px"}
                                    img_height={"36px"}
                                    img_alt={"dwc-sold"}
                                    amount={hfgSold}
                                />
                            </AnimationComponent>
                        </div>
                        <div className="relative">
                            <AnimationComponent y={'100'} duration={1} delay={1}>
                                <SaleCard
                                    tittle={'Total USDT Raised'}
                                    img={raisedIcon}
                                    img_width={"44px"}
                                    img_height={"36px"}
                                    img_alt={"usd-raised"}
                                    amount={usdRaised}
                                />
                            </AnimationComponent>
                        </div>
                        <div className="relative">
                            <AnimationComponent y={'100'} duration={1} delay={1.2}>
                                <SaleCard
                                    tittle={'Round Left'}
                                    img={roundLeftIocn}
                                    img_width={"100px"}
                                    img_height={"45px"}
                                    img_alt={"round-left"}
                                    amount={"3"}
                                />
                            </AnimationComponent>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-5 gap-x-5 md:gap-x-8 listBox mb-14 lg:mb-20">
                    {
                        rounds.map((value, idx) => {
                            return (
                                <RoundPage
                                    address={value.address}
                                    price={value.price}
                                    ocAllotted={value.ocAllotted}
                                    startTime={value.startTime}
                                    idx={idx + 1}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default Projects