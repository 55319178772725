import Countdown from "react-countdown";
import './BalanceOverview.css'
import AnimationComponent from '../AnimationComponent';
import { toLocale } from '../../config/helpers';
import { useSaleUserData } from "../../hooks/useContractData";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { formatUnits } from "viem";


const BalanceOverview = () => {
    const [id, setId] = useState(0);
    const { userInfo, dwcBalance } = useSaleUserData(id);
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setId(queryParams.get('id') || 0);
    }, [location.search]);

    const formattedUserInfo = useMemo(() => {
        if (!userInfo) {
            return null;
        }

        return {
            usdcSpent: toLocale(formatUnits(userInfo.usdcSpent, 6)),
            usdtSpent: toLocale(formatUnits(userInfo.usdtSpent, 6)),
            totalSpent: toLocale(formatUnits(userInfo.totalSpent, 6)),
            hfBought: toLocale(formatUnits(userInfo.hfBought, 18)),
        };
    }, [userInfo]);

    if (!formattedUserInfo) {
        return (
            <section className="balanceOverview">
                <div className="oc_container">
                    <div className="bg-primary border-2 border-secondary-100 rounded-xl p-4 text-center">
                        <p>Loading data or no data available...</p>
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section className="balanceOverview">

            <div className="oc_container relative">
                {/* <AnimationComponent x={"100"} duration={1.2} scale={0}>
                    <div className="absolute top-3 lg:top-0 -right-8 sm:right-0">
                        <img src="images/titleframe.svg" alt="" width={""} height={""} className="w-[12vw] lg:w-[15vw] 2xl:w-[15vw] block hue-rotate-[40deg] blinkers" />
                    </div>
                </AnimationComponent>
                <AnimationComponent x={"-100"} duration={1.2} scale={0}>
                    <div className="absolute top-3 lg:top-0 -left-8 sm:left-0 rotate-180">
                        <img src="images/titleframe.svg" alt="" width={""} height={""} className="w-[12vw] lg:w-[15vw] 2xl:w-[15vw] block hue-rotate-[40deg] blinkers" />
                    </div>
                </AnimationComponent> */}
                <AnimationComponent y={"100"} duration={1.2} >
                    <div className="title mb-10">
                        <h2 className='text-4xl	md:text-5xl'>TMT Balance Overview</h2>
                    </div>
                </AnimationComponent>
                <AnimationComponent y={"100"} duration={1.2} scale={0} delay={0.2}>
                    <div className="balanceOverviewBox">
                        <ul>
                            <li><p>Your TMT Balance </p><strong> {toLocale(dwcBalance)} TMT</strong></li>
                            <li><p>USDC Invested </p><strong>{formattedUserInfo.usdcSpent} USDC </strong></li>
                            <li><p>USDT Invested </p><strong>{formattedUserInfo.usdtSpent} USDT</strong></li>
                            <li><p>Investment in USD </p><strong>${formattedUserInfo.totalSpent}</strong></li>
                            <li><p>TMT Bought </p><strong>{formattedUserInfo.hfBought} TMT</strong></li>

                            <li><p>Claim After </p><strong className='countDown'>

                                <Countdown date={1735516800000} />

                            </strong></li>
                        </ul>
                        {/* <div className="buttonGrp">
                            <button className="oc_btn py-2 px-3 mx-auto flex text-center justify-center">{"Claim"}</button>

                        </div> */}
                    </div>
                </AnimationComponent>
            </div>
        </section>
    )
}

export default BalanceOverview