import './PublicSale.css'
import OCAnimOrig from '../../Assets/images/techmont-coin.gif'
import heroBgShape from '../../Assets/images/hero-bg-shape.png'
import ProgressiveImage from "react-progressive-graceful-image";
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import AnimationComponent from '../AnimationComponent';
import { useAccount } from 'wagmi';
import { shortAddress } from '../../config/helpers';
import toast from 'react-hot-toast';
import { useSaleEligible } from '../../hooks/useSaleEligible';
import { url } from '../../config';
import Button_Components from '../Button_Components';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import SaleCard from '../Projects/SaleCard';
import { useGraphData } from '../../hooks/useGraph';


const PublicSale = () => {
    const { hfgSold } = useGraphData()
    const { address } = useAccount()
    const id = localStorage.getItem('Active') || ''
    const { eligible } = useSaleEligible(id)
    const notify = () => toast.success('Copied Successfully!')

    return (
        <>

            <section className="publicSale section-padding relative">
                {/* <div className='bg-video w-full h-full absolute top-0 left-0 opacity-10'>
                    <video autoPlay loop playsInline muted className="hero-video w-full h-full object-cover bg-black" >
                        <source src="https://videos.pexels.com/video-files/4389377/4389377-uhd_2732_1440_30fps.mp4" type="video/mp4" />
                    </video>
                </div> */}
                <div className='w-full h-full absolute top-0 left-0 opacity-15 md:opacity-10'>
                    <img src={heroBgShape} className='w-full h-full object-cover object-right-bottom animate-pulse' alt='hero-background-shape'></img>
                </div>
                <div className="container">
                    <div className="flex flex-col-reverse md:flex-row items-center">
                        <div className="w-full max-w-2xl mx-auto text-center">
                            <div className="infobox">
                                <AnimationComponent y={'100'} duration={1}>
                                    <AnimationComponent x={'80'} duration={2} delay={0.1} easeType={"elastic.out(1,0.3,0.8)"}>
                                        <div className="imageBox mb-8 md:mb-12">
                                            <ProgressiveImage delay={0} src={OCAnimOrig} placeholder={OCAnimOrig}>
                                                {(src) => <img src={src} alt="TMT" className='mx-auto max-w-20 md:max-w-24' />}
                                            </ProgressiveImage>
                                        </div>
                                    </AnimationComponent>
                                    <h1>Unlock Early Access to TMT Tokens, Scaling New Heights in Digital Ownership</h1>
                                    <p className="mt-4 mb-0">Be a Trailblazer in Techmont’s ITO and Lead the Future.</p>
                                    <p className="mt-4 mb-0">Seize this opportunity to be at the forefront of innovation in decentralized finance. Participate in Techmont's ITO rounds and explore a new era of digital ownership!</p>

                                    {(eligible && address) &&
                                        <div className='mt-5'>

                                            <h3 className='mt-4'>Copy Referral Link</h3>
                                            <div className="buttonGrp">
                                                {eligible && <CopyToClipboard text={`${url}?referrer=${address}`}>
                                                    <button onClick={notify} className="oc_btn copyRefferalCode py-3 px-5 text-center"><span>{`${url}?referrer=${shortAddress(address)}`}</span> <CopyAllIcon className='ms-1' /></button>
                                                </CopyToClipboard>}
                                                {
                                                    !eligible && <button className="oc_btn copyRefferalCode py-3 px-5 text-center">
                                                        <span>Not eligible ( min. investment of $10 reqd. )</span>
                                                    </button>
                                                }
                                                <Link to="/referdetails"> <button className="oc_btn mt-3  py-3 px-5 text-center">Referral Activity Details</button></Link>
                                            </div>
                                        </div>
                                    }
                                    <div className='flex gap-6 sm:gap-8 mt-10 mx-auto w-full justify-center'>
                                        <a href='/#buySection1'>
                                            <Button_Components text={"ITO Details"} fun={""} />
                                        </a>
                                        <a href='#' target='blank'>
                                            <Button_Components text={"Buy TMT"} fun={""} />

                                        </a>
                                    </div>
                                </AnimationComponent>
                            </div>
                        </div>
                        {/* <div className="w-full md-w-2/4">
                            <AnimationComponent x={'80'} duration={2} delay={0.1} easeType={"elastic.out(1,0.3,0.8)"}>
                                <div className="imageBox">
                                    <ProgressiveImage delay={0} src={OCAnimOrig} placeholder={OCAnimOrig}>
                                        {(src) => <img src={src} alt="TMT" className='mx-auto md:ml-auto' />}
                                    </ProgressiveImage>

                                </div>
                            </AnimationComponent>
                        </div> */}
                    </div>
                    {/* <div className="w-80 lg:w-96 relative ddf">
                        <AnimationComponent y={'100'} duration={1} delay={0.8}>
                            <SaleCard
                                tittle={'Total DWC Sold'}
                                img={"images/dwc-sold.webp"}
                                img_width={"36px"}
                                img_height={"36px"}
                                img_alt={"dwc-sold"}
                                amount={hfgSold}
                            />
                        </AnimationComponent>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default PublicSale