import React from 'react'
import Faqs from '../../Components/Faqs/Faqs'
import BalanceOverview from '../../Components/BalanceOverview/BalanceOverview'
import PublicSale from '../../Components/PublicSale/PublicSale'
import Statisics from '../../Components/Statisics/Statisics'
import BuyOc from '../../Components/BuyOc/BuyOc'
import BuySell from '../../Components/ButSell/BuySell'

const SaleDetails = () => {

    return (
        <>
            <PublicSale />
            <BuySell />
            <BuyOc />
            <Statisics />
            <BalanceOverview />
            <Faqs />
        </>
    )
}

export default SaleDetails