import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import Loader from '../Components/Loader/Loader'

const Layout = () => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, []);


    return (
        <>
            {
                loading === true ? <Loader /> : <>
                    <Header />
                    <div className='main'>
                        <Outlet />
                    </div>
                    <Footer />
                </>
            }
            {/* <Header />
            <div className='main overflow-hidden'>
                <Outlet />
            </div>
            <Footer /> */}
        </>
    )
}

export default Layout