import { toast } from "react-hot-toast";

export const addToken = async ({ data }) => {
    try {
        // Check if MetaMask is installed
        if (typeof window.ethereum !== 'undefined') {
          // Ask MetaMask to add the token
          const wasAdded = await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20',
              options: {
                address: data.address, // The address of the token
                name: data.name,
                symbol: data.symbol, // A string representing the token symbol
                decimals: data.decimals, // A number representing the number of decimals
                image: data.image , // A string url of the token logo
              },
            },
          });

          if (wasAdded) {
            toast.success('Token was successfully added!');
          } else {
            toast.error('Token was not added');
          }
        } else {
          toast.error('Cannot add token');
        }
      } catch (error) {
        console.error('Error', error);
      }
}